import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"


export default function Privacy () {

    return (
      <Layout navbar="transparent">
        <Head title="Privacy Policy of the Onport Platform" description="The Privacy Policy of the Onport Platform"/>
         
        <BackgroundShape style={{shape: "clip-fixed-small", color: "bg-privacy-sm", particles: false}}/>
        <Container style={{classes: "container" }}>
          <div>
            <Header content={{title: "Privacy & Cookies Policy", text: ""}} style={{color: "text-center"}}/>
          </div>
        </Container>
        <Container style={{classes: "container privacy mb-5" }}>
        <p>
          <span>
            <strong>Effective Date: July 18</strong>
          </span>
          <span>
            <strong>th</strong>
          </span>
          <span>
            <strong>, 2024</strong>
          </span>
        </p>
        <h2>1. ABOUT ONPORT AND THIS PRIVACY POLICY</h2>
<p>We are JBUX Limited (“Onport”, “we”, “us”, “our”), and we are committed to protecting your privacy.</p>
<p>Onport is the controller for the purposes of the General Data Protection Regulation (the “GDPR”) a company registered in England and Wales (Company No. 09496834), with its registered office located at  1 Manor Drive, Bath, BA1 7TY, United Kingdom, and manages the website “onport.com” and the App “app.jetti.io” (together here referred as the “website”).</p>
<p>This Privacy Policy sets out the information that we collect, the reasons for which we collect that information, and how we can use and share that information.</p>
<p>The Website and the services provided by Onport are not intended to be used by children. If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, you can contact us at <a href="mailto:support+gdpr@onport.com">support+gdpr@onport.com</a>.</p>

<h2>2. THIRD PARTY WEBSITES</h2>
<p>Our Website may contain links to and from the websites of our partners, advertisers, among others. If you follow a link to any of these websites, please note that this Privacy Policy does not apply to those websites. We are not responsible or liable for the privacy policies or practices of those websites, so please check their policies before you submit any personal data to those websites.</p>

<h2>3. WHAT INFORMATION DO WE COLLECT, AND HOW?</h2>
<p>When you interact with us, whether by using our Website or communicating with us, we may collect the following information about you:</p>
<ul>
    <li><strong>Information you give us:</strong> you may give us information about yourself if you sign up for an account on our Website, complete any online forms (such as registration or request forms), opt-in to receive our newsletters and special offers, or correspond with us (by email, telephone, instant chat, social media or otherwise).
        <ul>
            <li>Depending on what you provide, this information can include your name, address or location, phone number, email address, financial information and tax number.</li>
        </ul>
    </li>
    <li><strong>Information we receive from other accounts or sources:</strong> we can receive information about you from other sources, such as from your other accounts or other websites, including social media providers like LinkedIn and analytics partners.
        <ul>
            <li>We supplement the data we have from you with data from data append services such as public data in order to better serve you with content or promotions. We will append this data to our existing customer information to better understand customers’ interests.</li>
        </ul>
    </li>
    <li><strong>Information our IT systems collect about you:</strong> each time you visit the Website, certain information will be created and automatically recorded by our IT systems. That information includes:
        <ul>
            <li><strong>Cookies:</strong> you can see more details about cookies in section 7, but these small text files can help improve your experience with the Website and make it easier to interact with us. This may include storing your location to save you having to re-enter information when returning to. We also use cookies to provide you with tailored advertising;</li>
            <li><strong>Device information:</strong> the information provided by your device will differ depending on which type of device you are using (whether it’s a PC, Mac, iPhone, Android, or otherwise) and the settings on that device, but includes the type of device you have, the device’s IP address, the browser you are using, your mobile network provider (for mobile devices), the pages you have visited, your time zone and country location, and crash or download error reports.</li>
        </ul>
    </li>
</ul>

<h2>4. WHAT DO YOU USE MY INFORMATION FOR?</h2>
<p>To provide you services and the Website, including to administer your account and to optimize your experience we need to use the information that we collect in a number of different ways.</p>
<p>Please scroll down to find out the detailed purposes for which we collect your information, and what specific information is collected:</p>
<ul>
    <li><strong>To register and manage your account in our Website:</strong> we may collect your name, email, password, role and other additional details you may wish to add in your account, like company phone number and addresses.</li>
    <li><strong>To fulfill the orders you make through our website:</strong> we collect your Name, addresses, phone number and order details, like the products you are buying.</li>
    <li><strong>To provide you with support services (including helping you to solve any issue you have with our services):</strong> we collect your name, email, and your contact history with us.</li>
    <li><strong>To administer, maintain and optimize our Website and our services:</strong> we collect your device information (such as your device IP address and device type), Cookie identifiers and browsing information.</li>
    <li><strong>To send you marketing communications:</strong> we collect your Name and Email.</li>
    <li><strong>Carry on research, analysis, enquiries and surveys on your use of our website or app:</strong> we collect your Name; Addresses; Email; and content of survey responses.</li>
    <li><strong>Advertising and retarget advertising:</strong> we collect your email, cookie identifiers and device information.</li>
</ul>
<p>When you visit or log in to our website, cookies and similar technologies may be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email or home address. We (or service providers on our behalf) may then send communications and marketing to these email or home addresses. You may opt out of receiving this advertising by visiting https://app.retention.com/optout</p>

<h2>5. WHO DO YOU SHARE MY INFORMATION WITH?</h2>
<p>To provide the Website and our services, we work with a number of carefully selected third parties. To do this, we may share your information with these third parties in the following limited circumstances:</p>
<ul>
    <li><strong>Third-party service providers for business purposes:</strong> to function properly, we rely on a number of carefully selected third parties to provide us with services and products. We permit these companies to use your personal information only to the extent necessary to provide us with their services and products. Below you can find the types of third parties we use:
        <ul>
            <li>Payment providers that we use to process your financial information (including your credit/debit card details or bank details) so that we can collect payment from you. Our current providers are based in Canada and Ireland.</li>
            <li>Analytics and search engine providers, like Google, that we use to assist us in the improvement and optimization of the Website. These providers are based in the United States.</li>
            <li>Marketing Tools providers that help us to enable our marketing. These providers are based in the United States.</li>
            <li>IT/technology providers that we use to support, maintain and provide our technology and IT infrastructure that supports our Website and the storage of your information. This includes but not limits to AWS that we use to host your information and that it is based in the United States.</li>
        </ul>
    </li>
    <li><strong>Advertisers for marketing purposes:</strong> we can provide your information to our advertising and social media partners (including, but not limited to, Facebook, Instagram, Twitter and Pinterest, where you choose) where they require the data to select and serve relevant advertisements about our products and services to you and others;</li>
    <li><strong>to comply with legal requests:</strong> on occasion we may be required to liaise with various regulators and law enforcement agencies in a number of different countries, whether as a result of law, a court order, or another legal process. Although we dispute requests wherever suitable, in some cases we may have to share your information with the regulators or law enforcement agencies. Where we consider it appropriate, and provided we are not prohibited from doing so by law or court order, we will attempt to notify you of these legal demands; and</li>
    <li><strong>Aggregated information with third parties:</strong> we may aggregate your information with the information of other customers, creating a dataset of information about the usage of our Website, and other general, grouped information about our customers on the basis of our legitimate interest to understand the usage of our services. Although this dataset is aggregated and anonymized, meaning it cannot identify you as an individual, it provides a valuable insight into the use of our Website and we may therefore share it with select third parties. These parties may include providers of plugins or similar technologies to help measure traffic, our partners and other providers.</li>
</ul>

<h2>6. TRANSFERS OF YOUR PERSONAL DATA</h2>
<p>To provide our Website and our services, in accordance with the purposes set out above, we may transfer and store the personal information that we collect from you to a destination inside or outside of the United Kingdom, mostly from and to the European Economic Area (“EEA”) but also to the United States.</p>
<p>When transferring personal information to one of our third party services’ providers set out above, we rely on different adequacy and security measures to ensure we comply with the relevant privacy regulations.</p>

<h2>7. COOKIES</h2>
<p>We use technology such as "cookies" to collect information and store your online preferences. Cookies are small pieces of information sent by a web server to a web browser, which allows the server to uniquely identify the browser on each page.</p>
<p>We use the following categories of cookies on our Website:</p>
<ul>
    <li><strong>NECESSARY COOKIES:</strong> Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.</li>
    <li><strong>ANALYTICS COOKIES:</strong> Analytics cookies help us to improve our website by collecting and reporting information on its usage.</li>
    <li><strong>MARKETING COOKIES:</strong> Marketing cookies help us and our partners to show you relevant ads and recommendations across different platforms.</li>
</ul>
<p>For more information on the individual cookies we use and the purposes for which we use them, please check our Cookie Policy.</p>

<h2>8. HOW DO WE PROTECT YOUR INFORMATION?</h2>
<p>We use a variety of physical and technical measures to keep your personal data safe and to prevent unauthorized access to, use or disclosure of it. Electronic data and databases are stored on secure computer systems and we control who has access to them (using both physical and electronic means). Our staff receive data protection training and we have a set of detailed data protection procedures which personnel are required to follow when handling personal data.</p>

<h2>9. YOUR RIGHTS</h2>
<p>You have a number of rights in relation to your personal information under data protection law. In relation to certain rights, we may ask you for information to confirm your identity and, where applicable, to help us search for your personal information. Except in rare cases, we will respond to you within 30 days after we have received any request (including any identification documents requested).</p>
<p>You have the following rights:</p>
<ul>
    <li><strong>to be informed about how your personal information is being used;</strong></li>
    <li><strong>to access the personal information we hold about you;</strong></li>
    <li><strong>to request the correction of inaccurate personal information we hold about you;</strong></li>
    <li><strong>to request the erasure of your personal information in certain limited circumstances;</strong></li>
    <li><strong>to restrict processing of your personal information where certain requirements are met;</strong></li>
    <li><strong>to object to the processing of your personal information;</strong></li>
    <li><strong>to request that we transfer elements of your data either to you or another service provider;</strong></li>
    <li><strong>to object to certain automated decision-making processes using your personal information.</strong></li>
</ul>

<h2>10. CHANGES TO THIS PRIVACY POLICY</h2>
<p>We may update this Privacy Policy from time to time to ensure that it remains accurate. If we make any material changes, we will notify you by email (using the email address provided during registration) or through a notification on our Website. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

<h2>11. CONTACT US</h2>
<p>If you have any questions, comments, or concerns about how we handle your personal data, please contact us at <a href="mailto:support+gdpr@onport.com">support+gdpr@onport.com</a>. We will make every effort to address your concerns.</p>

<h2>12. UPDATES TO THIS POLICY</h2>
<p>Any changes we may make to this Privacy Policy in the future will be posted on this page and/or, where appropriate, notified to you by email. Please check back regularly to keep informed of updates or changes to this Privacy Policy.</p>
</Container>
      </Layout>
    )
}
